import React from 'react';
import { ContentModule } from '@last-rev/component-library';
// import BackToTop from '@last-rev/component-library/dist/components/BackToTop/BackToTop';
import { Page } from '@adid-festival-lr/graphql-sdk/dist';
// import Sky from './Sky';
import styled from '@emotion/styled';
import { m } from 'framer-motion';
// import FadeIn from './FadeIn';
const PageGeneral = ({ header, hero, contents, footer }: Page & { __typename: string }) => {
  const arrLength = contents?.length;
  const [elRefs, setElRefs] = React.useState([]);

  React.useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill(null)
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, []);
  console.log('Page->contentsRef', elRefs);
  return (
    <div className={!!hero ? 'with-hero' : ''}>
      {/* <Sky key={elRefs?.length} refs={elRefs} /> */}
      {header ? <ContentModule {...(header as any)} withHero={!!hero} /> : null}
      <PageMain>
        {hero ? <ContentModule {...(hero as any)} key={hero?.id} /> : null}
        {contents?.map((content: any, _idx: number) => (
          // <ContentModule key={content?.id} {...content} component="section" />
          <ContentRoot key={content?.id}>
            <ContentModule key={content?.id} {...content} component="section" />
          </ContentRoot>
        ))}
      </PageMain>
      {/* {!disableBackToTop ? <BackToTop /> : null} */}
      {footer ? <ContentModule {...(footer as any)} /> : null}
    </div>
  );
};

const ContentRoot = styled(m.div)`
  * {
    opacity: 1;
  }
`;
const PageMain = styled(m.div)`
  * {
    opacity: 1;
  }
`;
export default PageGeneral;
